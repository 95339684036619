import jwtDecode from "jwt-decode";
import { makeObservable, observable, computed, configure } from "mobx";
import { getProfile } from "../api/profile";
import {
  removeLocalStorageExistingItem,
  removeSessionStorageExistingItem,
} from "../services/logoutHelper";
import { AUTHENTICATION_STORE_NAMES } from "../constants/authenticationStoreNames";
import { PATHNAMES } from "../constants/pathnames";
import mixpanel from "mixpanel-browser";

/*eslint no-undef: "error"*/

const {
  JWT,
  REFRESH_TOKEN,
  FIRST_LOGIN,
  FIRST_NAME,
  CARE_PLAN_COMPLETED,
  USER_DATA,
  TAGGED_APPOINTMENT,
  MINIMUM_PROFILE_COMPLETED,
  CALENDAR_TO_PREGNANCY_WEEKS,
  REMEMBER_ME,
} = AUTHENTICATION_STORE_NAMES;

configure({
  enforceActions: "never",
});

class AuthenticationStore {
  user = undefined;
  userId = undefined;
  chatParameters = undefined;
  loadingUser = false;
  loadingGetChatParameters = false;
  redirectLogout = undefined;
  rememberMe = undefined;

  constructor() {
    makeObservable(this, {
      user: observable,
      userId: observable,
      chatParameters: observable,
      loadingUser: observable,
      loadingGetChatParameters: observable,
      userInfo: computed,
      redirectLogout: observable,
      rememberMe: observable,
    });
    const jwt = this.getJWTToken();
    try {
      const data = jwtDecode(jwt);
      this.userId = data.sub;
      this.refreshUserInfo();
    } catch (e) {}
  }

  @computed
  get userInfo() {
    if (this.user === undefined) {
      this.refreshUserInfo();
      return;
    }
    return this.user;
  }

  refreshUserInfo = async () => {
    if (this.loadingUser || !this.userId) {
      return;
    }
    try {
      this.loadingUser = true;
      const { data } = await getProfile(this.userId);
      this.user = data.patient;
      this.setUserData(data.patient);
    } catch (e) {
      this.user = undefined;
    } finally {
      this.loadingUser = false;
    }
  };

  @computed
  setJWTToken = (token, rememberToken = true) => {
    if (rememberToken) {
      this.setRememberMe(rememberToken);
      localStorage.setItem(JWT, token);
    } else {
      sessionStorage.setItem(JWT, token);
    }
    const { sub, exp } = jwtDecode(token);
    this.userId = sub;
    this.expiresAt = exp;
    this.refreshUserInfo();
  };

  setRefreshToken = (token, rememberToken) => {
    if (rememberToken) {
      localStorage.setItem(REFRESH_TOKEN, token);
    } else {
      sessionStorage.setItem(REFRESH_TOKEN, token);
    }
  };

  getJWTToken = () => {
    const token = localStorage.getItem(JWT) || sessionStorage.getItem(JWT);
    if (token) {
      const { sub, exp } = jwtDecode(token);
      this.userId = sub;
      this.expiresAt = exp;
    }
    return token;
  };
  getRefreshToken = () => {
    return (
      localStorage.getItem(REFRESH_TOKEN) ||
      sessionStorage.getItem(REFRESH_TOKEN)
    );
  };

  setUser = (user) => {
    this.user = user;
  };

  setIsFirstLogin = (isFirstLogin) => {
    this.getRememberMe
      ? localStorage.setItem(FIRST_LOGIN, isFirstLogin)
      : sessionStorage.setItem(FIRST_LOGIN, isFirstLogin);
  };

  getIsFirstLogin = () => {
    return (
      localStorage.getItem(FIRST_LOGIN) || sessionStorage.getItem(FIRST_LOGIN)
    );
  };

  setFirstName = (firstName) => {
    this.getRememberMe
      ? localStorage.setItem(FIRST_NAME, firstName)
      : sessionStorage.setItem(FIRST_NAME, firstName);
  };

  getIsCarePlanCompleted = () => {
    return (
      sessionStorage.getItem(CARE_PLAN_COMPLETED) ||
      localStorage.getItem(CARE_PLAN_COMPLETED)
    );
  };

  setCarePlanCompleted = (carePlanCompleted) => {
    this.getRememberMe
      ? localStorage.setItem(CARE_PLAN_COMPLETED, carePlanCompleted)
      : sessionStorage.setItem(CARE_PLAN_COMPLETED, carePlanCompleted);
  };

  getIsFirstName = () => {
    return (
      sessionStorage.getItem(FIRST_NAME) || localStorage.getItem(FIRST_NAME)
    );
  };

  setUserData = (user) => {
    this.getRememberMe
      ? localStorage.setItem(USER_DATA, JSON.stringify(user))
      : sessionStorage.setItem(USER_DATA, JSON.stringify(user));
    if (this.userId) {
      mixpanel.identify(`${this.userId}`);
    }
  };

  getUserData = () => {
    let value =
      sessionStorage.getItem(USER_DATA) || localStorage.getItem(USER_DATA);
    return value && JSON.parse(value);
  };

  setTaggedAppointment = (taggedAppointment) => {
    this.getRememberMe
      ? localStorage.setItem(TAGGED_APPOINTMENT, taggedAppointment)
      : sessionStorage.setItem(TAGGED_APPOINTMENT, taggedAppointment);
  };

  getTaggedAppointment = () => {
    return (
      sessionStorage.getItem(TAGGED_APPOINTMENT) ||
      localStorage.getItem(TAGGED_APPOINTMENT)
    );
  };

  setMinimumProfileCompleted = (minimumProfileCompleted) => {
    this.getRememberMe
      ? localStorage.setItem(MINIMUM_PROFILE_COMPLETED, minimumProfileCompleted)
      : sessionStorage.setItem(
          MINIMUM_PROFILE_COMPLETED,
          minimumProfileCompleted
        );
  };

  getMinimumProfileCompleted = () => {
    return (
      localStorage.getItem(MINIMUM_PROFILE_COMPLETED) ||
      sessionStorage.getItem(MINIMUM_PROFILE_COMPLETED)
    );
  };

  setCalendarToPregnancyWeeks = (calendarToPregnancyWeeks) => {
    this.getRememberMe
      ? localStorage.setItem(
          CALENDAR_TO_PREGNANCY_WEEKS,
          JSON.stringify(calendarToPregnancyWeeks)
        )
      : sessionStorage.setItem(
          CALENDAR_TO_PREGNANCY_WEEKS,
          JSON.stringify(calendarToPregnancyWeeks)
        );
  };

  getCalendarToPregnancyWeeks = () => {
    let value =
      sessionStorage.getItem(CALENDAR_TO_PREGNANCY_WEEKS) ||
      localStorage.getItem(CALENDAR_TO_PREGNANCY_WEEKS);
    return value && JSON.parse(value);
  };

  setRememberMe = (rememberMe) => {
    localStorage.setItem(REMEMBER_ME, rememberMe);
  };

  getRememberMe = () => {
    return localStorage.getItem(REMEMBER_ME);
  };


  removeToken = async () => {
    if (this.getRememberMe() === "true") {
      localStorage.clear();
    } else {
      sessionStorage.clear();
    }

    removeLocalStorageExistingItem("appointment");
    removeLocalStorageExistingItem("scheduledAppointment");
    removeLocalStorageExistingItem("appointmentsArray");
    removeLocalStorageExistingItem(REMEMBER_ME);
    removeLocalStorageExistingItem("userData");
    removeLocalStorageExistingItem(JWT);

    // Reset mixpanel identity on logout
    mixpanel.reset();

    if (!this.getJWTToken()) {
      this.redirectLogout = `${PATHNAMES.LOGIN}?logout_successful=true`;
    }
  };

  removeTaggedAppointment = () => {
    this.getRememberMe() === "true"
      ? removeLocalStorageExistingItem(TAGGED_APPOINTMENT)
      : removeSessionStorageExistingItem(TAGGED_APPOINTMENT);
  };

  resetRedirectLogout = () => {
    this.redirectLogout = undefined;
  };
}

export const authenticationStore = new AuthenticationStore();
