import { Grid } from "@material-ui/core";
import { PuffLoader } from "react-spinners";
import { getAppointments } from "../../api/scheduling";
import useAsyncEffect from "use-async-effect";
import { useEffect, useMemo, useState } from "react";
import { ScheduleableAppointment } from "./SchedulingTypes";
import { Alert } from "@material-ui/lab";
import { useLocation } from "react-router-dom";
import { appointmentStore } from "../../stores/appointment-store";
import { retNumber } from "./CarouselAppointmentTypes/CarouselAppointmentTypes";
import moment from "moment";
import { YYYY_MM_DD_ALTERNATIVE } from "../../constants/dateFormat";
import { getDashboard } from "../../api/dashboard";
import * as Sentry from "@sentry/react";
import { useAuth } from "../../providers/Auth";
import { useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

type ApptData = Record<string, ScheduleableAppointment[]>;

// handles /book_auth route that enables us to send direct links to book specific appointment types
const Book = () => {
  const [apptData, setApptData] = useState<ApptData | null>(null);
  const [err, setErr] = useState<string | null>(null);
  const [assignedDept, setAssignedDept] = useState<number | null>(null);
  const { search } = useLocation();
  const history = useHistoryWithPathBasedReload();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const auth = useAuth() as undefined | { user: { id: number } };

  useEffect(() => {
    if (err) Sentry.captureException(err);
  }, [err]);

  // get appointment and dashboard data data
  useAsyncEffect(
    async (isMounted) => {
      if (
        auth === undefined ||
        "user" in auth === false ||
        "id" in auth?.user === false
      )
        return setErr("cannot find user ID!");
      else {
        setErr(null);
      }

      try {
        const res = await getDashboard(auth.user.id);
        const department =
          res?.data.dashboard.patient.department.emr_departmentid;
        if (department) setAssignedDept(department);
      } catch {
        setErr("Error fetching dashboard data!");
      }

      try {
        const res = await getAppointments();
        if (isMounted() && res && res.data) {
          const data = res.data as ApptData;
          setApptData(data);
        } else throw new Error();
      } catch {
        setErr("Error fetching appointment data!");
      }
    },
    [auth]
  );

  // write to local storage and redirect to /departments
  // also check if patient has an assigned department, redirect to /location if so
  useEffect(() => {
    if (apptData) {
      const apptTypeIdData: string | null = searchParams.get(
        "appointment_type_id"
      );
      if (!apptTypeIdData) return setErr("URL is missing appointment_type_id!");
      const apptTypeId = parseInt(apptTypeIdData, 10);
      if (!apptTypeId) return setErr("appointment_type_id value is invalid!");
      const apptMap: Record<number, ScheduleableAppointment> = {};
      apptData.appointment_types.forEach((a) => {
        apptMap[a.id] = a;
      });
      if (!(apptTypeId in apptMap))
        return setErr("appointment ID not found in appointment data!");
      const appointmentType = apptMap[apptTypeId];

      // some appts are handled by acuity
      if (appointmentType.acuity_link) {
        window.location.href = appointmentType.acuity_link;
        return;
      }
      // most appts are handled in the regular scheduling flow
      appointmentStore.setAppointment({
        appointment_type_id: appointmentType.id,
        duration_minutes: retNumber(appointmentType.duration),
        card_title: appointmentType.patient_display_name,
        appointment_type_description: appointmentType.patient_display_name,
        card_is_virtual: appointmentType.is_virtual,
        begin_date_range: moment(moment().add(1, "days")).format(
          YYYY_MM_DD_ALTERNATIVE
        ),
        shortName: appointmentType.shortname,
      });
      if (assignedDept) {
        localStorage.setItem("department", `${assignedDept}`);
        history.push(
          `/location?appointment_type_id=${apptTypeId}&book_auth=true`,
          {
            from: "book_auth",
          }
        );
      } else {
        history.push(
          `/departments?appointment_type_id=${apptTypeId}&book_auth=true`,
          {
            from: "book_auth",
          }
        );
      }
    }
  }, [apptData]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{ flexGrow: 1 }}
    >
      {err ? (
        <Alert severity="error">
          This link is invalid—please send us a message so we can help!
        </Alert>
      ) : (
        <PuffLoader />
      )}
    </Grid>
  );
};

export default Book;
