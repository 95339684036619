import { z } from "zod";

export interface ScheduleableAppointment {
  id: number;
  patient_display_name: string;
  description: {
    paragraph: string;
  }[];
  insurance_accepted: string | null;
  acuity_link?: string | null;
  ga_start?: number | null;
  ga_end?: number | null;
  shortname: string;
  duration: string;
  is_virtual: boolean;
}

export const appointmentTypes = [
  "pregnancy_care_types",
  "coaching_types",
  "gyn_care_types",
  "preconception_care_types",
] as const;

export type AppointmentType = (typeof appointmentTypes)[number];

export const scheduleableAppointmentSchema = z.object({
  id: z.number(),
  patient_display_name: z.string(),
  description: z.array(
    z.object({
      paragraph: z.string(),
    })
  ),
  insurance_accepted: z.string().nullish(),
  acuity_link: z.string().nullish(),
  ga_start: z.number().nullish(),
  ga_end: z.number().nullish(),
  shortname: z.string(),
  duration: z.string(),
  is_virtual: z.boolean(),
});

export const appointmentTypesSchema = z
  .record(
    z.enum([...appointmentTypes, "appointment_types"]),
    z.array(scheduleableAppointmentSchema)
  )
  .transform((result) => {
    if (result.appointment_types) {
      delete result.appointment_types;
    }
    return result as Record<AppointmentType, ScheduleableAppointment[]>;
  });

export type ScheduleableAppointmentTypes = Record<
  AppointmentType,
  ScheduleableAppointment[]
>;

export interface ScheduledAppointment {
  id: number;
  appointment_type_id: number;
  external_appointment_id: string;
  appointment_card_key: string;
  start_time: Date;
  duration_minutes: number;
  patient_id: number;
  add_note_enabled: boolean;
  virtual_link: string;
  appointment_type_description: string;
  appointment_type_shortname: string;
  abbrev_card_description: null;
  notes: any[];
  department: Department;
  card_title: string;
  scheduled: boolean;
  begin_date_range: Date;
  appointment_id: number;
  card_is_virtual: boolean;
  reschedule: boolean;
}

export interface Department {
  display_name: string;
  display_city: string;
  state: string;
  display_address: string;
  emr_departmentid: number;
  department_name: string;
}

export const patientJourneyAppointmentWindowDateSchema = z.object({
  journey: z.object({
    patient_journey_appointment_window_dates: z.array(
      z.object({
        card_id: z.number(),
        card_key: z.string(),
        card_title: z.string(),
        card_is_in_office: z.boolean(),
        card_is_virtual: z.boolean(),
        card_appointment_type_id: z.number(),
        week_in_journey: z.number(),
        begin_date_range: z.string(),
        end_date_range: z.string(),
        abbrev_card_description: z.string(),
        appointment_type_description: z.string(),
      })
    ),
  }),
});

export interface PatientJourneyAppointmentWindowDate {
  card_id: number;
  card_key: string;
  card_title: string;
  card_is_in_office: boolean;
  card_is_virtual: boolean;
  card_appointment_type_id: number;
  week_in_journey: number;
  begin_date_range: string;
  end_date_range: string;
  abbrev_card_description: string;
  appointment_type_description: string;
}
