import { SchedulingCard } from "./SchedulingCard";
import {
  PatientJourneyAppointmentWindowDate,
  ScheduledAppointment,
} from "./SchedulingTypes";
import { intakeFormStore } from "../../stores/intake-form-store";
import { ReactElement, useEffect, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { appointmentStore } from "../../stores/appointment-store";
import { PATHNAMES } from "../../constants/pathnames";
import moment from "moment";
import { useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

type PreviewMode = "next" | "past" | "new";

interface SchedulingCalendarProps {
  scheduledAppointments: ScheduledAppointment[];
  journeyWindows: PatientJourneyAppointmentWindowDate[];
}

const getInitiallySelectedAppointment = (
  scheduledAppointments: ScheduledAppointment[],
  apptPreviewMode: PreviewMode
) => {
  if (scheduledAppointments.length >= 1) {
    switch (apptPreviewMode) {
      case "next": {
        // select the next appt that's in the future
        const newSelectedAppt = scheduledAppointments.find(
          ({ start_time }) => new Date(start_time) > new Date()
        );
        return newSelectedAppt ? { ...newSelectedAppt, scheduled: true } : null;
      }
      default:
        return null;
    }
  }
  return null;
};

export const SchedulingCalendar = ({
  scheduledAppointments,
  journeyWindows,
}: SchedulingCalendarProps) => {
  const history = useHistoryWithPathBasedReload();
  // component state
  const [apptPreviewMode, setApptPreviewMode] = useState<PreviewMode>("next");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedAppt, setSelectedAppt] = useState<ScheduledAppointment |  null>(
    getInitiallySelectedAppointment(scheduledAppointments, apptPreviewMode)
  );

  // check if the intake form has been completed
  const intakeFormComplete =
    intakeFormStore.getIntakeFormData()?.patient_intake_form_answers
      ?.completed === true;

  // check when the appointment is, and update the preview mode
  // we use this to update the title above the appt preview card and enable/disable buttons
  useEffect(() => {
    if (selectedAppt !== null) {
      // if appt is in the future, set appropriate header
      if (new Date() < new Date(selectedAppt["start_time"])) {
        setApptPreviewMode("next");
      } else if (new Date() < new Date(selectedAppt["begin_date_range"])) {
        setApptPreviewMode("new");
      } else {
        // same w/ past appts
        setApptPreviewMode("past");
      }
    }
  }, [selectedAppt]);

  // if the patient is looking at a booked appt *in the future* we need to enable the edit button
  const editAppointment =
    apptPreviewMode === "next" && selectedAppt !== null
      ? () => {
          appointmentStore.setAppointment({
            ...(selectedAppt as object),
            card_title: selectedAppt["card_title"],
            appointment_id:
              selectedAppt["id"] || selectedAppt["appointment_id"],
            appointment_type_description:
              selectedAppt["appointment_type_description"],
            begin_date_range: selectedAppt["start_time"],
            card_is_virtual: !!selectedAppt["virtual_link"],
            reschedule: true,
          });
          history.push(PATHNAMES.VIEW_APPOINTMENT);
        }
      : undefined;

  // create various highlighted days in the calendar for booked appts, important dates in pregnancy, etc.
  const dayRenderer = (
    muiDay: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: ReactElement
  ) => {
    // convert muiDate to regular Date
    const day = new Date(`${muiDay}`);
    let highlightAsScheduledAppt = false;
    let highlightAsJourneyAppt = false;
    // we need to keep track of which corners to round—because of the structure
    // we can't easily use CSS pseudo classes to do this, so we'll use an integer
    let highlightJourneyState = 0;
    let isInPast = false;
    scheduledAppointments.forEach(({ start_time }) => {
      // turn serialized date into Date instance
      const apptDay = moment(start_time).utc();
      const isMatch =
        moment(day).format("M-DD-yyyy") === apptDay.format("M-DD-yyyy");
      if (isMatch && dayInCurrentMonth) {
        highlightAsScheduledAppt = true;
        isInPast = apptDay < moment();
      }
    });

    journeyWindows.forEach(({ begin_date_range, end_date_range }) => {
      // turn serialized date into Date instance
      const rangeStart = new Date(begin_date_range);
      const rangeEnd = new Date(end_date_range);
      if (day >= rangeStart && day <= rangeEnd && dayInCurrentMonth)
        highlightAsJourneyAppt = true;
      if (day.toDateString() === rangeStart.toDateString())
        highlightJourneyState = 1;
      else if (day.toDateString() === rangeEnd.toDateString())
        highlightJourneyState = 2;
    });

    return (
      <div
        className={`
    ${highlightAsScheduledAppt ? "day-appt-scheduled" : ""}
    ${highlightAsJourneyAppt ? "day-appt-journey" : ""}
    ${highlightJourneyState === 1 ? "day-appt-journey-start" : ""}
    ${highlightJourneyState === 2 ? "day-appt-journey-end" : ""}
    ${isInPast ? "day-past-appt" : ""}
    `}
      >
        {dayComponent}
      </div>
    );
  };
  // if user clicks on a date w/ something on it, change state
  // otherwise, ignore
  const handleDatePick = (e: MaterialUiPickersDate) => {
    const chosenDay = new Date(`${e}`);
    // we need to figure out if the day the user clicked on has *anything* scheduled on it
    const scheduleMatches = scheduledAppointments.flatMap(
      (a: ScheduledAppointment) => {
        const { start_time } = a;
        const apptDay = new Date(start_time);
        // since we're using flatMap, we'll only get an array of the appointments on this day
        if (apptDay.toDateString() === chosenDay.toDateString()) return a;
        else return [];
      }
    );
    if (scheduleMatches.length > 0) {
      // then we can show that appointment and update the date picker
      setSelectedDate(chosenDay);
      setSelectedAppt({ ...scheduleMatches[0], scheduled: true });
      // return so that scheduled appts take precedence over journey windows
      return;
    }
    //   alternatively, the user could also have clicked on a journey appt
    //   and should be given the opporunity to book
    const journeyMatches = journeyWindows.flatMap(
      (window: PatientJourneyAppointmentWindowDate) => {
        const { begin_date_range, end_date_range } = window;
        const rangeStart = new Date(begin_date_range);
        const rangeEnd = new Date(end_date_range);
        if (chosenDay >= rangeStart && chosenDay <= rangeEnd) return window;
        else return [];
      }
    );
    if (journeyMatches.length) {
      setSelectedDate(chosenDay);
      // @ts-ignore
      setSelectedAppt(journeyMatches[0]);
    }
  };

  // put together the calendar component
  const calendar = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        renderDay={dayRenderer}
        disableToolbar
        variant={"static"}
        value={selectedDate}
        onChange={handleDatePick}
      />
    </MuiPickersUtilsProvider>
  );

  let apptPreview;
  // choose appt preview header based on the kind of appt shown
  let previewHeader: string = "";
  switch (apptPreviewMode) {
    case "next": {
      previewHeader = "Upcoming appointment";
      break;
    }
    case "new": {
      previewHeader = "Future appointment";
      break;
    }
    case "past": {
      previewHeader = "Past appointment";
      break;
    }
  }
  // put together the appt preview card
  if (selectedAppt !== null) {
    apptPreview = (
      <div>
        <SchedulingCard
          cardHeading={previewHeader}
          appointment={selectedAppt}
          editAppointment={editAppointment}
          appointmentIsVirtual={
            "virtual_link" in selectedAppt &&
            selectedAppt["virtual_link"] !== null
          }
          // don't show intake form for past appts
          showIntakeButton={
            new Date(selectedAppt["start_time"]) > new Date() &&
            !intakeFormComplete &&
            selectedAppt["appointment_type_shortname"] === "VFIR"
          }
          appointmentName={
            selectedAppt["abbrev_card_description"]
              ? selectedAppt["abbrev_card_description"]
              : selectedAppt["appointment_type_description"]
          }
          bookingBlocked={false}
        />
      </div>
    );
  }

  return (
    // stack the calendar on top of the preview card
    <div className={"scheduling-flex-item"}>
      <div>{calendar}</div>
      <div>{apptPreview}</div>
    </div>
  );
};
