import { getAppointments } from "../../../api/scheduling";
import {
  appointmentTypes,
  appointmentTypesSchema,
  ScheduleableAppointmentTypes,
} from "../SchedulingTypes";

export const loadAppointmentTypes = async () => {
  const appointmentsTypesData = await getAppointments().then((response) =>
    appointmentTypesSchema.parse(response?.data)
  );

  const sortedAppointmentTypes = sortAppointmentsByGA(appointmentsTypesData);
  const filteredAppointmentTypes = hideCoachingForGynPatients(
    sortedAppointmentTypes
  );
  appointmentTypes.forEach((appointmentType) => {
    if ((filteredAppointmentTypes[appointmentType]?.length ?? 0) === 0) {
      delete filteredAppointmentTypes[appointmentType];
    }
  });
  return filteredAppointmentTypes;
};

const sortAppointmentsByGA = (
  appointmentTypes: ScheduleableAppointmentTypes
) => {
  return Object.entries(appointmentTypes).reduce(
    (sorted, [category, categoryAppts]) => ({
      ...sorted,
      [category]: categoryAppts.sort(
        (a, b) => (a.ga_start ?? 99) - (b.ga_start ?? 99)
      ),
    }),
    {} as ScheduleableAppointmentTypes
  );
};

const hideCoachingForGynPatients = (
  appointmentTypes: ScheduleableAppointmentTypes
) => ({
  ...appointmentTypes,
  coaching_types:
    appointmentTypes.gyn_care_types.length > 0
      ? []
      : appointmentTypes.coaching_types,
});
