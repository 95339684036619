import { ThemeProvider } from "@material-ui/core/styles";
import * as commonMaterial from "../../materialDesignShared";
import { CustomButton } from "../../materialDesignShared";
import "./Scheduling.scss";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { AppointmentType } from "./SchedulingTypes";
import { useState } from "react";
import { SchedulingCalendar } from "./SchedulingCalendar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CarouselAppointmentTypes } from "./CarouselAppointmentTypes/CarouselAppointmentTypes";
import { useSchedulingData } from "./useSchedulingData";

const appointmentTypeLabels = {
  pregnancy_care_types: "Pregnancy",
  coaching_types: "Coaching",
  gyn_care_types: "Gynecology",
  preconception_care_types: "Preconception",
} as const;

export const Scheduling = () => {
  const appointmentDataResponse = useSchedulingData();
  const [userSelectedAppointmentTypes, setUserSelectedAppointmentTypes] =
    useState<AppointmentType | null>(null);

  const isSmallViewport = useMediaQuery(
    commonMaterial.theme.breakpoints.down("md")
  );

  if (appointmentDataResponse.state === "error") {
    throw new Error('Failed to load scheduling data.')
  } else if (appointmentDataResponse.state !== "success") {
    return (
      <ThemeProvider theme={commonMaterial.theme}>
        <Box display={'flex'} pt={32} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  const {
    scheduledAppointments,
    appointmentData,
    appointmentTypes,
    appointmentWindows,
    homeClinicSelected,
  } = appointmentDataResponse.data;

  const selectedAppointmentType =
    userSelectedAppointmentTypes ?? appointmentTypes[0];

  return (
    <ThemeProvider theme={commonMaterial.theme}>
      {!isSmallViewport && (
        <Grid container>
          <Grid item md={6}>
            <h1 className="scheduling-title">My Calendar</h1>
          </Grid>
          <Grid item md={6}>
            <h1 className="scheduling-title">Book an Appointment</h1>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        alignItems={isSmallViewport ? "center" : "flex-start"}
        style={{
          flexDirection: isSmallViewport ? "column-reverse" : "row",
        }}
      >
        <Grid item md={6} xs={12}>
          {isSmallViewport && <h1 className="scheduling-title">My Calendar</h1>}
          {/* 62px top margin aligns calendar w/ the top of the appt cards */}
          <Box marginTop={isSmallViewport ? "0px" : "62px"}>
            <SchedulingCalendar
              scheduledAppointments={scheduledAppointments}
              journeyWindows={appointmentWindows}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            {isSmallViewport && (
              <h1 className="scheduling-title hide-md-above">
                Book an Appointment
              </h1>
            )}
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent="center"
              alignItems={"center"}
            >
              <div className={"appt-type-selector"}>
                {appointmentTypes.map((key) => (
                  <CustomButton
                    key={key}
                    className={
                      key === selectedAppointmentType
                        ? "appt-type-selected appt-type"
                        : "appt-type"
                    }
                    onClick={() => setUserSelectedAppointmentTypes(key)}
                  >
                    {appointmentTypeLabels[key]}
                  </CustomButton>
                ))}
              </div>
              <Box mt={"1.6rem"}>
                <CarouselAppointmentTypes
                  appointmentTypes={appointmentData[selectedAppointmentType]}
                  selectedDepartment={homeClinicSelected}
                  loadingInfoUser={false}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
