// effect hooks
// only show the calendar if the user has appointments scheduled
import useAsyncEffect from "use-async-effect";
import {
  AppointmentType,
  appointmentTypes,
  PatientJourneyAppointmentWindowDate,
  ScheduleableAppointment,
  ScheduledAppointment
} from "./SchedulingTypes";
import { authenticationStore } from "../../stores/authentication-store";
import { useState } from "react";
import { loadHomeClinicSelected } from "./helpers/loadHomeClinicSelected";
import { loadAppointmentTypes } from "./helpers/loadAppointmentTypes";
import { loadAppointmentWindows } from "./helpers/loadAppointmentWindows";
import { loadScheduledAppointments } from "./helpers/loadScheduledAppointments";

type Loadable<T> =
  | {
      readonly state: "uninitialized" | "loading" | "error";
    }
  | {
      readonly state: "success";
      readonly data: T;
    };

const useLoadableDataById = <T>(load: (userId: string) => Promise<T>) => {
  const [response, setResponse] = useState<Loadable<T>>({
    state: "uninitialized",
  });

  const userId = authenticationStore.userId;

  useAsyncEffect(async (isMounted) => {
    if (userId == undefined) {
      return;
    }
    setResponse({ state: "loading" });
    await load(userId)
      .then((data) => {
        if (isMounted()) {
          setResponse({ state: "success", data });
        }
      })
      .catch((error) => {
        console.error(error);
        setResponse({ state: "error" });
      });
  }, []);

  return response;
};

const useLoadableData = <T>(load: () => Promise<T>) => {
  const [response, setResponse] = useState<Loadable<T>>({
    state: "uninitialized",
  });

  useAsyncEffect(async (isMounted) => {
    setResponse({ state: "loading" });
    await load()
      .then((data) => {
        if (isMounted()) {
          setResponse({ state: "success", data });
        }
      })
      .catch((error) => {
        console.error(error);
        setResponse({ state: "error" });
      });
  }, []);

  return response;
};

export const useSchedulingData = (): Loadable<{
  homeClinicSelected: boolean;
  appointmentData: Record<AppointmentType, ScheduleableAppointment[]>;
  appointmentTypes: AppointmentType[];
  appointmentWindows: PatientJourneyAppointmentWindowDate[];
  scheduledAppointments: ScheduledAppointment[];
}> => {
  const homeClinicSelected = useLoadableDataById(loadHomeClinicSelected);
  const appointmentData = useLoadableData(loadAppointmentTypes);
  const appointmentWindows = useLoadableDataById(loadAppointmentWindows);
  const scheduledAppointments = useLoadableDataById(loadScheduledAppointments);

  if (
    homeClinicSelected.state === "error" ||
    appointmentData.state === "error" ||
    appointmentWindows.state === "error" ||
    scheduledAppointments.state === "error"
  ) {
    return { state: "error" } as const;
  } else if (
    homeClinicSelected.state === "success" &&
    appointmentData.state === "success" &&
    appointmentWindows.state === "success" &&
    scheduledAppointments.state === "success"
  ) {
    return {
      state: "success",
      data: {
        appointmentData: appointmentData.data,
        homeClinicSelected: homeClinicSelected.data,
        appointmentTypes: Object.keys(appointmentData.data).filter(
          (key): key is AppointmentType => appointmentTypes.includes(key as any)
        ),
        appointmentWindows: appointmentWindows.data,
        scheduledAppointments: scheduledAppointments.data,
      },
    } as const;
  } else {
    return { state: "loading" } as const;
  }
};
