import { ThemeProvider } from "@material-ui/core/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get } from "lodash";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./CarouselAppointmentTypes.scss";
import * as commonMaterial from "../../../materialDesignShared";
import { HtmlTooltip } from "../../../materialDesignShared";
import { appointmentStore } from "../../../stores/appointment-store";
import { openInNewTab } from "../../../services/redirectionHelper";
import { YYYY_MM_DD_ALTERNATIVE } from "../../../constants/dateFormat";
import { PATHNAMES } from "../../../constants/pathnames";

import "../../../translations/i18n";
import { useHistoryWithPathBasedReload } from "../../App/LinkWithPathBasedReload";
import { toSnakeCase } from "../../../helpers/caseHelpers.js";

export const retNumber = (myString) => {
  let num = myString.replace(/[^0-9]/g, "");
  return parseInt(num, 10);
};
export const CarouselAppointmentTypes = ({
  appointmentTypes,
  selectedDepartment,
  loadingInfoUser,
}) => {
  const history = useHistoryWithPathBasedReload();
  const { t: translations } = useTranslation();

  return (
    <ThemeProvider theme={commonMaterial.theme}>
      <div className="carousel-appointments-slider">
        {appointmentTypes?.map((appointmentType) => (
          <div className="item-container" key={appointmentType.shortname}>
            <div>
              <div className="appointment-type-title">
                {appointmentType.insurance_accepted && (
                  <HtmlTooltip
                    title={
                      <span className="tooltip-body">
                        {translations("insurance_info")}
                      </span>
                    }
                    arrow={true}
                    enterTouchDelay={-100}
                    leaveTouchDelay={6000}
                  >
                    <div className="label-insurance">
                      <span className="label">
                        {appointmentType.insurance_accepted}
                      </span>

                      <img
                        className="label-insurance-icon"
                        src="/icons/question-circle.svg"
                        alt={"question mark"}
                      ></img>
                    </div>
                  </HtmlTooltip>
                )}
                <div className="patient-display-name">
                  {appointmentType.patient_display_name}
                </div>
                {appointmentType.is_virtual ? (
                  <div className="virtual-label">Virtual</div>
                ) : (
                  <div className="office-visit-label">Office Visit</div>
                )}
              </div>

              <div className="appointment-type-description">
                {appointmentType.description.map((section) => {
                  if (get(section, "paragraph")) {
                    return (
                      <p
                        className="appointment-type-text"
                        key={section.paragraph.slice(0, 10)}
                      >
                        {ReactHtmlParser(section.paragraph)}
                      </p>
                    );
                  }
                  if (get(section, "list")) {
                    return (
                      <div className="list">
                        <p className="list-title">
                          {ReactHtmlParser(section.list.title)}
                        </p>
                        <ul>
                          {section.list.items.map((item) => (
                            <li>{ReactHtmlParser(item)}</li>
                          ))}
                        </ul>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div>
              <commonMaterial.CustomYellowButton
                id={`select_appointment__appointment_type__${toSnakeCase(appointmentType.patient_display_name)}`}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                style={{ padding: "3px 23px" }}
                disabled={loadingInfoUser}
                onClick={() => {
                  if (!appointmentType.acuity_link) {
                    appointmentStore.setAppointment({
                      appointment_type_id: appointmentType.id,
                      duration_minutes: retNumber(appointmentType.duration),
                      card_title: appointmentType.patient_display_name,
                      appointment_type_description:
                        appointmentType.patient_display_name,
                      card_is_virtual: appointmentType.is_virtual,
                      begin_date_range: moment(moment().add(1, "days")).format(
                        YYYY_MM_DD_ALTERNATIVE
                      ),
                      shortName: appointmentType.shortname,
                    });

                    if (selectedDepartment) {
                      history.push(
                        `${PATHNAMES.LOCATION}?appointment_type_id=${appointmentType.id}`
                      );
                    } else {
                      history.push(
                        `${PATHNAMES.DEPARTMENTS}?appointment_type_id=${appointmentType.id}`
                      );
                    }
                  } else {
                    openInNewTab(appointmentType.acuity_link);
                  }
                }}
              >
                SELECT
              </commonMaterial.CustomYellowButton>
            </div>
          </div>
        ))}
      </div>
    </ThemeProvider>
  );
};
