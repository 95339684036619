import moment from "moment/moment";
import * as commonMaterial from "../../materialDesignShared";
import CompleteIntakeButton from "../CompleteIntakeButton";

import "./SchedulingCard.scss";

interface SchedulingCardProps {
  cardHeading?: string;
  appointment: any;
  appointmentIsVirtual: boolean;
  showIntakeButton: boolean;
  appointmentName: string;
  editAppointment?: () => void;
  bookingBlocked: boolean;
}

export const SchedulingCard = ({
  appointment,
  cardHeading,
  appointmentIsVirtual,
  showIntakeButton,
  editAppointment,
  appointmentName,
  bookingBlocked,
}: SchedulingCardProps) => {
  const showBorder = appointment.scheduled;

  const Label = () => (
    <div
      className={`${
        appointmentIsVirtual ? "virtual-label" : "office-visit-label"
      } ${bookingBlocked ? "appointment-label" : "margin-bottom-3"}`}
    >
      {appointmentIsVirtual ? "Virtual" : "Office Visit"}
    </div>
  );

  return (
    <div className="scheduling-next-appointment">
      <div className="card-title">{cardHeading}</div>
      <div className={`appointment-part ${showBorder ? "yellow-border" : ""}`}>
        <div className="first-box">
          <div className="appointment-title">{appointmentName}</div>
          {!bookingBlocked && <Label />}
        </div>

        {appointment?.scheduled && (
          <div className="appointment-reschedule-time">
            {moment(appointment.start_time).utc().format("ddd")},{" "}
            {moment(appointment.start_time).utc().format("MMM")}{" "}
            {moment(appointment.start_time).utc().format("DD")},{" "}
            {moment(appointment.start_time).utc().format("YYYY")}
            <br />
            {new Date(appointment.start_time).toLocaleTimeString("en-us", {
              timeZone: "UTC",
              timeStyle: "short",
            })}
          </div>
        )}

        {bookingBlocked && <Label />}

        {editAppointment && (
          <div className="edit-icon" onClick={editAppointment}>
            <img src="/icons/Edit@0,5x.svg" alt="edit-icon" />
          </div>
        )}
      </div>

      {showIntakeButton && (
        <CompleteIntakeButton customClassName="intakeButton__scheduling" />
      )}
    </div>
  );
};
