import { getJourney } from "../../../api/journey";
import {
  PatientJourneyAppointmentWindowDate,
  patientJourneyAppointmentWindowDateSchema,
} from "../SchedulingTypes";

export const loadAppointmentWindows = async (
  userId: string
): Promise<PatientJourneyAppointmentWindowDate[]> => {
  const journeyData = await getJourney(userId);
  return patientJourneyAppointmentWindowDateSchema.parse(journeyData?.data)
    .journey.patient_journey_appointment_window_dates;
};
